<template>
    <div class="row justify-content-center">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
            <h1 class="mt-4 font-weight-bolder"><i class="fas fa-plus mr-3"></i>Edit Test Program Date</h1>
            <hr>
            <div class="row" v-if="!isLoad">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-5 p-auto" v-if="form.id">
                    <div class="card w-100 p-0 shadow-xss border-0 rounded-lg overflow-hidden mr-1 p-3">
                        <form @submit.prevent="patchProgramTestDate" class="row p-2">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                <div class="row p-auto">
                                    <div class="col-lg-12 mt-4">
                                        <div class="row">
                                            <div class="form-group col-xl-6">
                                                <label for="">Tanggal Pendaftaran</label>
                                                <DatePicker v-model="form.reg_date" :range="true" ></DatePicker>
                                            </div>
                                            <div class="form-group col-xl-6">
                                                <label for="">Tanggal Tes</label>
                                                <DatePicker v-model="form.test_date" ></DatePicker>
                                            </div>
                                            <div class="form-group col-xl-12">
                                                <label for="">Tes Program</label>
                                                <select class="form-control" v-model="form.category_test" required>
                                                    <option value="TOEFL ITP">TOEFL ITP</option>
                                                    <option value="TOEFL iBT">TOEFL iBT</option>
                                                    <option value="TOEIC">TOEIC</option>
                                                    <option value="IELTS">IELTS</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 text-right">
                                        <button type="submit" class="btn btn-current">
                                            Submit
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import moment from 'moment';

export default {
    components: {
        DatePicker
    },
    data() {
        return {
            idRegisterDate: this.$route.params.idRegisterDate,
            tpd: null,
            form: {
                id: null,
                reg_date: null,
                test_date: null,
                category_test: null,
            },
            isLoad: true,
        }
    },
    created() {
        this.getProgramTestDate()
    },
    methods: {
        async getProgramTestDate() {
            await axios.get(`${process.env.VUE_APP_URL_API}/admin/setting/test-program-date/detail?id=${this.idRegisterDate}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                if (res.data.success) {
                    this.tpd = res.data.data
                    this.form.id = this.tpd.id
                    this.form.reg_date = [
                        moment(this.tpd.reg_date[0]).toDate(),
                        moment(this.tpd.reg_date[1]).toDate()
                    ];
                    this.form.test_date = moment(this.tpd.test_date).toDate();
                    this.form.category_test = this.tpd.category_test;
                }
                this.isLoad = false
            }).catch(err => {
                if (err.response.status == 422) {
                    this.$swal({
                        toast: true,
                        title: 'Diagnostic Test!',
                        text: err.response.data.message[0],
                        icon: 'error',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
            })
        },
        async patchProgramTestDate() {
            
            var data = this.form
            data.reg_date[0] = moment(data.reg_date[0]).format('YYYY-MM-DD');
            data.reg_date[1] = moment(data.reg_date[1]).format('YYYY-MM-DD');
            data.test_date = moment(data.test_date).format('YYYY-MM-DD');
            await axios.patch(`${process.env.VUE_APP_URL_API}/admin/setting/test-program-date`, data, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.token}`
                },
            }).then(res => {
                this.$swal({
                    toast: true,
                    title: 'Success Create Test Program Date!',
                    text: res.data.message,
                    icon: 'success',
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                })
                this.$router.push({ name: 'SettingsTestProgramDateAdmin' })
            }).catch(err => {
                if (err.response.status == 422) {
                    this.validationErrors = Object.values(err.response.data.errors);
                    this.$swal({
                        toast: true,
                        title: 'Failed Create Test Program Date!',
                        text: this.validationErrors[0][0],
                        icon: 'error',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
            })
        }
    }
}
</script>


<style>
.mx-datepicker {
    position: relative;
}

.mx-datepicker-range,
.mx-datepicker {
    width: 100%;
}

.mx-input {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    line-height: 50px;
    height: 50px;
    font-size: 14px;
    display: inline-block;
    border: 2px #eee solid;
}

.mx-input:hover,
.mx-input:focus {
    border-color: #fbaf03 !important;
}
</style>
